import React, { useState, useRef, useEffect, useCallback } from "react";
import axios from "axios";
import L from "leaflet";
import {
  MapContainer,
  LayersControl,
  TileLayer,
  Marker,
  Polyline,
  GeoJSON,
  Circle,
  useMap,
  Tooltip,
  useMapEvents,
  ZoomControl,
  WMSTileLayer
} from "react-leaflet";
import * as turf from "@turf/turf";
import { BingLayer } from "react-leaflet-bing-v2";
import "leaflet/dist/leaflet.css";
import "./css/map_view.css";
import PointData from "../assets/sec_point";
import Linedata from "../assets/sec_line";
import RightSidebar from "./RightSidebar";
import LeftSidebar from "./LeftSidebar";
import TopNavbar from "./TopNavbar";
import {
  calculateDistance,
  getMidpoint,
  createDistanceIcon,
  pointsOnLine,
  convertClassNameString,
} from "../utils/map";

// Import custom marker icon images
import markerIcon from "leaflet/dist/images/marker-icon.png";
import markerIconShadow from "leaflet/dist/images/marker-shadow.png";
import RightSideBaseLayer from './BaseLayer/RightSideBaseLayer';
import RightSideDrawBar from "./BaseLayer/RightSideDrawBar";
import { FaMapMarkerAlt } from 'react-icons/fa';
import { renderToStaticMarkup } from 'react-dom/server';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
// import L from 'leaflet';
import 'leaflet-draw';
// import 'leaflet-geometryutil';
//import jsPDF from "jspdf";
import 'leaflet-geometryutil';




// const htPoleIcon = L.divIcon({
//   html: '<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg"><circle cx="12" cy="12" r="10" fill="blue" /></svg>',
//   className: 'custom-icon'
// });

// const transformerIcon = L.divIcon({
//   html: '<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg"><rect x="6" y="6" width="12" height="12" fill="green" /></svg>',
//   className: 'custom-icon'
// });

// const rmuIcon = L.divIcon({
//   html: '<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg"><polygon points="12,2 22,20 2,20" fill="red" /></svg>',
//   className: 'custom-icon'
// });

// const getIconByType = (type) => {
//   switch (type) {
//     case 'HT Pole':
//       return htPoleIcon;
//     case 'Transformer':
//       return transformerIcon;
//     case 'RMU':
//       return rmuIcon;
//     default:
//       return htPoleIcon; // Default icon if type doesn't match
//   }
// };
const triangleSVG = `
   <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="lightblue" stroke="blue" stroke-width="2">
    <polygon points="12,2 22,22 2,22" />
  </svg>
`;

const basicIcon = L.divIcon({
  html: triangleSVG,
  className: 'custom-icon',
  iconSize: [24, 24],
  iconAnchor: [12, 24],
});

const ZoomTracker = ({ setZoom }) => {
  // Hook into the map's events using useMapEvents
  useMapEvents({
    zoomend: (event) => {
      const zoomLevel = event.target.getZoom(); // Get the current zoom level
      setZoom(zoomLevel); // Update the zoom level in state
    },
  });

  return null; // This component doesn't render anything itself
};

/*import Markers from './VenueMarkers';*/
const MapComponent = ({ authDetails}) => {
  //state = {};
  const { BaseLayer } = LayersControl;
  const mapRef = useRef(null);
  const geoJSONBetweenTraceRef = useRef(null);
  const [activeLayer, setActiveLayer] = useState("OpenStreetMap");
  const [sidebarVisible, setSidebarVisible] = useState(true);
  const [selectedFeature, setSelectedFeature] = useState(null);
  const [selectedFeatureId, setSelectedFeatureId] = useState([]);
  const [featureProperties, setFeatureProperties] = useState(null);
  const [zoom, setZoom] = useState(13);
  const [datapoint, setDataFromChild] = useState(PointData);
  const [dataLine, setLineDataFromChild] = useState(Linedata);
  const [pointLayer, setPointLayer] = useState(null);
  const [lineLayer, setLineLayer] = useState(null);
  const [center, setCenter] = useState([
    PointData.features[0].geometry.coordinates[1],
    PointData.features[0].geometry.coordinates[0],
  ]);
  const [className, setClassName] = useState("container-hide");
  const [poleClassName, setPoleClassName] = useState("pole-hide");
  const [pointDataElements, setPointDataElements] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [traceBetween, setTraceBetween] = useState([]);
  const [openedRightMenuOption, setOpenedRightMenuOption] = useState(null);
  const [circles, setCircles] = useState([]);
  const [networkDetails, setNNetworkDetails] = useState({
    pathLength: 0,
    spanCount: 0,
    Transformer: 0,
    Switch: 0,
    Capacity: 0,
  });
  const [isMeasuring, setIsMeasuring] = useState(false);

  const [points, setPoints] = useState([]);
  const [distances, setDistances] = useState([]);
  const [totalDistance, setTotalDistance] = useState(0);
  const [getidInRighSidebar, setGetIdInRightsidebar] = useState(null);

  const [cursorPosition, setCursorPosition] = useState(null);
  const [measuringPoint, setMeasuringPoint] = useState(null); // Example point
  const [lineColor, setLineColor] = useState("#0080ff");
  const [uniquePointTypes, setUniquePointTypes] = useState([]);
  const [selectedData, setSelectedData] = useState(null); // State for selected layer detailed data
  const [filteredData, setFilteredData] = useState([]);
  const[measureDetail,setMeasureDetail] = useState();


  
  const gujaratBounds = [
    [19.1226, 67.1629], // Southwest coordinates (bottom-left)
    [25.3963, 75.4769], // Northeast coordinates (top-right)
  ];
  
  const [visibleElements, setVisibleElements] = useState({
    gentry: true,
    ht_pole: true,
    transformer: true,
    rmu: true,
    rmu_with_tc: true,
    switch: true,
    fuse: true,
  });
  const [selectedOptions, setSelectedOptions] = useState({
    organization: null,
    network: null,
    hvNetwork: null,
    selectedProjects: [],
    drawnLines: [], // Example: Array to store drawn lines data
  });

  // const[activeBaseLayer,setActiveBaseLayer] = useState();
  const[selectedTaluka,setSelectedTaluka] = useState();
  // const[isDistrictVisble,setIsDistrictVisible] = useState(false);
  const[isTalukaVisible, setIsTalukaVisible] = useState(false); 
  const [geojsonData, setGeojsonData] = useState(null);
  const[geojsonTalukaData,setGeojsonTalukaData] = useState();
  // const[villages,setVillages] = useState();
  const[zindex, setZindex] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  const[isMapVisble,setIsMapVisible] = useState(true);
  const [measureResult, setMeasureResult] = useState(null); // Result of measurement
  const [drawnLayers, setDrawnLayers] = useState([]);
  const[measurementVisible,setMeasurementVisible] = useState(false);
  const [pointGeometry, setPointGeometry] = useState(null);
  // const[baselayerVisble,setBaselayerVisble] = useState();
  
  const [selectedDistrictId, setSelectedDistrictId] = useState();
 
  const [measureMode, setMeasureMode] = useState('');

  const[districtStyle, setDistrictStyle] = useState({color:'#0B7D7D', opacity:1,fillColor: '#008000', fillOpacity:'0.3',zIndex:1200});
  const[talukaStyle, setTalukaStyle] = useState({color:'#000000', opacity:1,fillColor: '#0000FF', fillOpacity:'0.2', zIndex:1250});
  const[companyStyle, setCompanyStyle] = useState({color:'#000000', opacity:0.7, fillColor: '#000000', fillOpacity:'0.3', zIndex:1000});
  const[highwayStyle, setHighwayStyle] = useState({color:'#000000', opacity:0.4});

  const[circleStyle,setCircleStyle] = useState({color:'#0000FF', opacity:1,fillColor: '#8B0000', fillOpacity:'0.2', zIndex:1001});
  const[divisionStyle,setDivisionStyle] = useState({color:'#701010', opacity:1,fillColor: '#B8860B', fillOpacity:'0.2', zIndex:1002});
  const[subDivisionStyle,setSubDivisionStyle] = useState({color:'#000000', opacity:1,fillColor: '#000000', fillOpacity:'0.2', zIndex:1003});
  const [searchData, setSearchData] = useState({ project_id: '', category: ''});
  // const [organization, setOrganization] = useState([]);
const[clearnetwork,setClearnetwork] = useState(false);

  // const[highwayStyle,setHighwayStyle] = useState({color:"#00000", opacity:1});
  const handleClearNetwork = () => {
   
    setOrganization([]);
    setCircleData(null);
    setSubDivisionData(null);
    setDivisionData(null);
    setCircleGeojson(null);
    setCompanyGeojson(null);
    setDivisionGeojson(null);
    setSubDivisionData(null);
    setCircles([]);
    setEHVLinegeojson(false);
    setSubDivisiongeojson(null);
    setTraceBetween([]);
    setMarkers([]); // Set markers to an empty array
    // setVisibleElements([]);
    setDataFromChild([]);
    setLineDataFromChild([]);
    console.log("Clear All Network");

  };
  

  const handleMeasureChange = (mode) => {

    if(measureMode === mode){
    setMeasureMode('');

    }else{

      setMeasureMode(mode);
      setMeasurementVisible(true);
  
    }

  };


  // const handleBaseLayerChange= () =>{
  //   setBaselayerVisble(!baselayerVisble);
  //   setPointDataElements(false);
  //   // setBaselayerVisble
    
    
    // }

  const [baseLayer, setBaseLayer] = useState([]);

  // const handleBaseLayerChange = (data) => {
  //   setBaseLayer((prevBaseLayer) => {
  //     const exists = prevBaseLayer.includes(data);
  //     if (exists) {
  //       return prevBaseLayer.filter((item) => item !== data);
  //     } else {
  //       return [...prevBaseLayer, data];
  //     }
  //   });
  // };

  const handleBaseLayerChange = (data) => {

    if(baseLayer == data){
      setBaseLayer([]);

    }else{
    setBaseLayer([data]);

    }

    if(data == "Talukas"  && !baseLayer.includes("Talukas") && selectedDistrictId == null){
      setIsTalukaVisible(false);
      setErrorMessage("Please Select District");
      setBaseLayer([]);

    }else{
      setErrorMessage("");
      

    }
  };
  

  // const handleDistrictBaseLayerChange = () => {
  //   if (isDistrictVisble) {
  //     setIsDistrictVisible(false);
  //     // setIsTalukaVisible(true);
  //     // setActiveBaseLayer('');
  //     setBaselayerVisble(true);
  //   } else {
  //     setIsDistrictVisible(true);
  //     setIsTalukaVisible(false);
  //     setActiveBaseLayer('District');
  //   }
  // };
  const  handleClearLineNetwork = () =>{
    setDataFromChild([]);
    setLineDataFromChild([]);
    setLineDataGeoJSON(null)
    setEHVLinegeojson(null);
    setObjectgeojson(null);
    setClearnetwork(!clearnetwork);
    console.warn("handleClearLineNetwork");
  }

  const handleRefresh = () => {
    // setActiveBaseLayer('');
    setSelectedTaluka('');
    // setIsDistrictVisible(false);
    setIsTalukaVisible(false);
    setGeojsonTalukaData(null);
    setGeojsonData(null);
    setSelectedDistrictId(null);
  
  }

  // const handleTalukaBaseLayerChange = () => {
  //   console.log("handleTalukaBaseLayerChange - District Data: ", geojsonData);
    
  //   if (geojsonData) {
  //     setErrorMessage('');
      
  //     if (isTalukaVisible) {
  //       // If Taluka is currently visible, hide it
  //       setIsTalukaVisible(false);
  //     } else {
  //       // If Taluka is not visible, check if a District is selected
  //       if (activeBaseLayer) {
  //         // If a District is visible, show Taluka and hide District
  //         setIsTalukaVisible(true);
  //         setIsDistrictVisible(false);
  //         setSelectedTaluka('Taluka');
  //       } else {
  //         // If no District is selected, show an error message
  //         setErrorMessage('Please Select District');
  //       }
  //     }
  //   } else {
  //     // If geojsonData is null, show an error message
  //     setErrorMessage('Please Select District');
  //   }
  // };
  

  const newCalculateDistance = (latlng1, latlng2) => {
    const from = L.latLng(latlng1);
    const to = L.latLng(latlng2);
    return from.distanceTo(to) / 1000; // Distance in kilometers
  };
  const MyComponent = () => {
    useMapEvents({
      mousemove: (e) => {
        setCursorPosition(e.latlng);
      },
      dblclick: (e) => {
        handleDoubleClick(e);
      },
      click: (e) => {
        const newPoints = [...points, e.latlng];
        setPoints(newPoints);
        setMeasuringPoint(e.latlng);
        if (newPoints.length > 1) {
          const lastPoint = newPoints[newPoints.length - 2];
          const newDistance = newCalculateDistance(lastPoint, e.latlng);
          //const distance = calculateDistance(e.latlng, cursorPosition);
          const newTotalDistance = totalDistance + newDistance; // + distance;

          setDistances([...distances, newDistance]);
          setTotalDistance(newTotalDistance);
        }
      },
    });

    return null;
  };


  const handleDoubleClick = (e) => {
    setIsMeasuring(false);
  };

  const clearLengthMapping = () => {
    setCursorPosition(null); // Clear cursor position when turning off measuring
    setPoints([]);
    setDistances([]);
    setTotalDistance(0);
  };

  const handleToggleMeasuring = () => {
    setIsMeasuring(!isMeasuring);
    if (isMeasuring) {
      clearLengthMapping();
    }
  };

  // Create custom icon instance
  const customIcon = new L.Icon({
    iconUrl: markerIcon,
    shadowUrl: markerIconShadow,
    iconSize: [25, 41], // Size of the icon
    iconAnchor: [12, 41], // Point of the icon which will correspond to marker's location
    shadowSize: [41, 41], // Size of the shadow
    shadowAnchor: [12, 41], // Point of the shadow which will correspond to marker's location
    popupAnchor: [1, -34], // Point from which the popup should open relative to the iconAnchor
  });



  const handleDataFromChild = (newdata, dataline) => {
    setDataFromChild(newdata);
    setLineDataFromChild(dataline);

    // const map = mapRef.current;
    // const geoJsonLayer = L.geoJSON(dataline);
    // const bounds = geoJsonLayer.getBounds();
    // map.fitBounds(bounds);

    //setCenter([newdata.features[0].geometry.coordinates[1],newdata.features[0].geometry.coordinates[0]]);
  };


  useEffect(() => {
    // Update map center when center state changes
    if (mapRef.current) {
      mapRef.current.setView(center);
    }
  }, [center]);


  useEffect(() => {
    if (isMeasuring) {
      setMeasuringPoint(
        L.latLng(
          selectedFeature.geometry.coordinates[1],
          selectedFeature.geometry.coordinates[0]
        )
      );
    }
  }, [selectedFeature]);

  useEffect(() => {
    Object.keys(visibleElements).map((key) => {
      const elements = document.getElementsByClassName(
        `leaflet-marker-icon ${key}`
      );
      for (let i = 0; i < elements.length; i++) {
        if (visibleElements[key]) {
          elements[i].classList.remove("d-none");
        } else {
          elements[i].classList.add("d-none");
        }
      }
    });
  }, [visibleElements]);

  useEffect(() => {
    // Update map center when center state changes
    const anchor = document.querySelector(".leaflet-popup-close-button");
    if (anchor) {
      anchor.click();
    }

    if (traceBetween.length > 1) {
      var features = traceBetween;
      const point1 = traceToTopParent(features["0"].properties.id);
      const point2 = traceToTopParent(features["1"].properties.id);
      getNonCommonElements(point1, point2);
    }
  }, [traceBetween]);

  const getNonCommonElements = (json1, json2) => {
    var result = [];
    json1.map((item) => {
      var id = item.id;
      if (json2.some((item) => item.id === id)) {
        //result.push(item);
      } else {
        result.push(item);
      }
    });

    json2.map((item) => {
      var id = item.id;
      if (json1.some((item) => item.id === id)) {
        //result.push(item);
      } else {
        result.push(item);
      }
    });

    setSelectedFeatureId(result);
    const map = mapRef.current;
    geoJSONBetweenTraceRef.current = L.geoJSON(result, {
      style: function (feature) {
        var lineProps = JSON.parse(feature.line_props);
        if (lineProps.position === "underground") {
          return { dashArray: "8, 8", color: "green" };
        } else {
          return { color: "green" };
        }
      },
      //onEachFeature: onFeatureEachData
    }).addTo(map);

    calculateNetworkDetails(result);

    traceBetween.map((tracePoint, idx) => {
      highlightelement(
        [
          tracePoint.geometry.coordinates["1"],
          tracePoint.geometry.coordinates["0"],
        ],
        idx
      );
    });
    setTraceBetween([]);
    //return result;
  };

  const calculateCenter = (coords) => {
    if (coords.length === 0) return [0, 0];

    let totalLat = 0;
    let totalLng = 0;
    coords.forEach((coord) => {
      totalLat += parseFloat(coord[1]);
      totalLng += parseFloat(coord[0]);
    });

    const avgLat = totalLat / coords.length;
    const avgLng = totalLng / coords.length;

    setCenter([avgLat, avgLng]);
    return [avgLat, avgLng];
  };

  useEffect(() => {
    // Clear previous GeoJSON layers
    if (mapRef.current && pointLayer && lineLayer) {
      pointLayer.clearLayers();
      lineLayer.clearLayers();
    }

    // Add new GeoJSON layers
    if (mapRef.current) {
      const map = mapRef.current;

      setPointDataElements(datapoint);
      // setBaselayerVisble(false);

      // Add GeoJSON point layer
      const pointLayer = L.geoJSON(datapoint, {
        onEachFeature: onEachData,
      }).addTo(map);
      setPointLayer(pointLayer);

      // Add GeoJSON line layer
      const lineLayer = L.geoJSON(dataLine, {
        style: function (feature) {
          var lineProps = JSON.parse(feature.line_props);
          if (lineProps.position === "underground") {
            return { dashArray: "8, 8" };
          }
        },
        onEachFeature: onFeatureEachData,
      }).addTo(map);

      setLineLayer(lineLayer);

      map.on("zoomend", function () {
        // console.error(map.getZoom);
        if (map.getZoom() > 15) {
          setClassName("container-show");
        } else {
          setClassName("container-hide");
        }
        if (map.getZoom() > 17) {
          setPoleClassName("pole-show");
        } else {
          setPoleClassName("pole-hide");
        }
      });

      const tracing = L.geoJSON(selectedFeatureId, {
        style: function (feature) {
          var lineProps = JSON.parse(feature.line_props);
          if (lineProps.position === "underground") {
            return { dashArray: "8, 8", color: "red" };
          } else {
            return { color: "red" };
          }
        },
        //onEachFeature: onFeatureEachData
      }).addTo(map);
    }
  }, [
    datapoint,
    dataLine,
    selectedFeatureId,
    className,
    poleClassName,
    lineColor,
  ]);

  // const [map, setMap] = useState(null); // Keep reference to the map instance
  const [showGeoJson, setShowGeoJson] = useState(false); // State to toggle GeoJSON visibility

  // // Function to set map reference once it is loaded
  // const onMapCreated = (mapInstance) => {
  //   setMap(mapInstance);
  // };
  // // Effect to listen for zoom changes and control GeoJSON visibility
  // useEffect(() => {
  //   if (map) {
  //     map.on("zoomend", () => {

  //       const currentZoom = map.getZoom();
  //       console.log(currentZoom);
  //       if (currentZoom > 15) {
  //         setShowGeoJson(true);  // Show GeoJSON if zoom is greater than 15
  //       } else {
  //         setShowGeoJson(false); // Hide GeoJSON if zoom is 15 or below
  //       }
  //     });

  //     // Clean up the event listener on component unmount
  //     return () => {
  //       map.off("zoomend");
  //     };
  //   }
  // }, [map]);


  var result = [];
  const buildNestedStructure = (items, parentId) => {
    items.features.map((item) => {
      if (item.properties.parent_id === parentId) {
        const children = buildNestedStructure(items, item.id);
        if (children.length) {
          item.children = children;
        }
        item.properties.color = "green";
        result.push(item);
      }
    });

    return result;
  };

  const traceToTopParent = (id, path = []) => {
    if (!id || !dataLine) {
      return path;
    }
    const feature = dataLine.features.find(
      (feature) => feature.properties.id === id
    );
    if (feature && feature.id) {
      feature.properties.color = "green";
      path.push(feature); // Add current feature to the path

      if (feature?.properties?.parent_id) {
        return traceToTopParent(feature.properties.parent_id, path); // Recursively trace to the parent
      }
    }
    return path;
  };

  const handleUpwardTrace = (featureId) => {
    const nestedData = traceToTopParent(featureId);
    setSelectedFeatureId(nestedData);
    const map = mapRef.current;
    const tracing = L.geoJSON(nestedData, {
      style: function (feature) {
        var lineProps = JSON.parse(feature.line_props);
        if (lineProps.position === "underground") {
          return { dashArray: "8, 8", color: "green" };
        } else {
          return { color: "green" };
        }
      },
      //onEachFeature: onFeatureEachData
    }).addTo(map);
    calculateNetworkDetails(nestedData);
  };

  const calculatePathLength = (line) => {
    try {
      const lineString = turf.lineString(line.geometry.coordinates);
      const length = turf.length(lineString, { units: "kilometers" });
      return length;
    } catch (error) {
      console.error("Error calculating path length:", error);
      return 0;
    }
  };

  const handleFeatureClick = (featureId) => {
    const nestedData = buildNestedStructure(dataLine, featureId);
    const selectedFeature = dataLine.features.find(
      (feature) => feature.properties.parent_id === featureId
    );

    if (selectedFeature) {
      setSelectedFeatureId(nestedData);
      const map = mapRef.current;
      const tracing = L.geoJSON(nestedData, {
        style: function (feature) {
          var lineProps = JSON.parse(feature.line_props);
          if (lineProps.position === "underground") {
            return { dashArray: "8, 8", color: "red" };
          } else {
            return { color: "red" };
          }
        },
        //onEachFeature: onFeatureEachData
      }).addTo(map);
      calculateNetworkDetails(nestedData);
    }
  };
// console.log(activeBaseLayer,"activeBaseLayer");
  const onEachData = (data, layer) => {
    setGetIdInRightsidebar(data);
    let dataid = JSON.parse(data.properties.point_props); // data.properties.point_type +' - '+ data.id;
    const dataName = data.properties.point_type;

    let imgURL = "map_icons/ht_pole.svg"; // Default icon URL for HT pole
    switch (dataName) {
      case "Existing Pole(Double Circuit)":
        imgURL = "map_icons/existing_pole.svg";
        break;
      case "Fuse":
        imgURL = "map_icons/Fuse.svg";
        break;
      case "CTPT":
        imgURL = "map_icons/CTPT.svg";
        break;
      case "HT Pole":
        imgURL = "map_icons/ht_pole.svg";
        break;
      case "Switch":
        imgURL = "map_icons/Switch.svg";
        break;
      case "Gentry":
        imgURL = "map_icons/Gentry.svg";
        break;
      case "Transformer":
        imgURL = "map_icons/Transformer.svg";
        break;
      case "HT Route Point":
        imgURL = "map_icons/ht_route_point.svg";
        break;
      case "RMU":
        imgURL = "map_icons/RMU.svg";
        break;
      case "RMU with TC":
        imgURL = "map_icons/rmu_with_tc.svg";
        break;
    }

    const iconSize =
      dataName === "HT Pole" ||
      dataName === "Existing Pole(Double Circuit)" ||
      dataName === "HT Route Point"
        ? [10, 10]
        : [20, 20];
    const classText =
      dataName === "HT Pole" ||
      dataName === "Existing Pole(Double Circuit)" ||
      dataName === "HT Route Point"
        ? poleClassName
        : className;

    var tempName = classText + " " + convertClassNameString(dataName);

    // if (!visibleElements[convertClassNameString(dataName)]) {
    //   tempName = tempName + " d-none";
    // }

    const icon = L.icon({
      iconUrl: imgURL,
      iconSize: iconSize,
      className: tempName,
    });

    layer.setIcon(icon);

    layer
      .bindPopup(
        `
            <div class="leaflet-header">
                <h4>${dataName}</h4>
            </div>
            <div class="leaflet-body">
                <div class="modal-thumb">
                    <img src="${imgURL}" title="${dataName}" alt="${dataName}">
                </div>
                <div class="info-wrap">
                    <h6>Basic Details</h6>
                    <p class=""><span class="text-secondary">Feeder Name: </span><span class="word-break-all">${
                      data.properties.project_name
                    }</span></p>
                    <p class=""><span class="text-secondary">Element ID: </span><span class="word-break-all">${
                      data.properties.id
                    }</span></p>
                </div>
            </div>
            <div class="leaflet-footer">
                <div class="btn-group">
                    <button class="btn-custom" id="properties" type="button">Properties</button>
                    <button class="btn-custom" id="tracing" type="button">Tracing</button>
                    ${
                      dataName === "Gentry"
                        ? '<button class="btn-custom" id="network-check-button" type="button">Check Network</button>'
                        : ""
                    }
                    ${
                      dataName === "Transformer"
                        ? '<button class="btn-custom" id="view-sld" type="button">View SLD</button>'
                        : ""
                    }
                    <button class="btn-custom d-none" id="upward-tracing-button" type="button">Upward Tracing</button>
                    <button class="btn-custom d-none" id="downward-tracing-button" type="button">Downward Tracing</button>
                    <button class="btn-custom d-none" id="between-tracing-button" type="button">Between Tracing</button>
                </div> 
            </div>
        `
      )
      .on("popupopen", () => setupPopupEventHandlers(data))
      .on("popupclose", () => closePopupEventHandlers(data));

    layer.bindTooltip("Id: " + data.properties.id); // Adding tooltip
  };

  const closePopupEventHandlers = (data) => {
    //setOpenedRightMenuOption(null);
  };

  const calculateNetworkDetails = (pathData) => {
    var length = 0;
    pathData.map((tracePoint, idx) => {
      length = length + calculatePathLength(tracePoint);
    });

    var points = pointsOnLine(pathData, datapoint);
    var transformers = 0;
    var switchCount = 0;
    var totalXmrCapacity = 0;
    var Ids = [];
    if (points?.features && points.features.length) {
      points.features.map((point, idx) => {
        if (point.properties && !Ids.includes(point.properties.id)) {
          Ids.push(point.properties.id); // Add ID to array if it's not already included
          if (point.properties.point_type === "Transformer") transformers++;
          if (point.properties.point_type === "Switch") switchCount++;
          let point_props = JSON.parse(point.properties.point_props);
          totalXmrCapacity += Number(point_props.xmr_capacity ?? 0);
        }
      });
    }

    setNNetworkDetails({
      pathLength: length.toFixed(2),
      spanCount: pathData.length,
      Transformer: transformers,
      Switch: switchCount,
      Capacity: totalXmrCapacity,
    });
  };

  const setupPopupEventHandlers = (data) => {
    //setNetworkLength(null);
    setSelectedFeature(data);
    var featureId = data.properties.id;
    let result = JSON.parse(data.properties.point_props);
    result.point_name = data.properties.point_type;
    result.element_id = data.properties.id;
    setFeatureProperties(result);

    const viewSld = document.querySelector("#view-sld");
    if (viewSld) {
      viewSld.onclick = () =>
        setOpenedRightMenuOption({ tab: "sld", time: String(new Date()) });
    }

    const downwardButton = document.querySelector("#downward-tracing-button");
    if (downwardButton) {
      downwardButton.onclick = () => handleFeatureClick(featureId);
    }

    const upWardButton = document.querySelector("#upward-tracing-button");
    if (upWardButton) {
      upWardButton.onclick = () => handleUpwardTrace(featureId);
    }

    const networkCheck = document.querySelector("#network-check-button");
    if (networkCheck) {
      networkCheck.onclick = () => checkNetwork(data);
    }

    const betweenButton = document.querySelector("#between-tracing-button");
    if (betweenButton) {
      betweenButton.onclick = () => {
        if (traceBetween.length === null) {
          setTraceBetween(data);
        } else {
          setTraceBetween((prevArray) => [...prevArray, data]);
        }
        //const nestedData = traceToTopParent(featureId);
      };
    }

    const propertiesBtn = document.querySelector("#properties");
    if (propertiesBtn) {
      propertiesBtn.onclick = () =>
        setOpenedRightMenuOption({
          tab: "properties",
          time: String(new Date()),
        });
    }

    const tracingBtn = document.querySelector("#tracing");
    if (tracingBtn) {
      tracingBtn.onclick = () =>
        setOpenedRightMenuOption({ tab: "tracing", time: String(new Date()) });
    }

    highlightelement([
      data.geometry.coordinates["1"],
      data.geometry.coordinates["0"],
    ]);

    if (geoJSONBetweenTraceRef.current !== null) {
      mapRef.current.removeLayer(geoJSONBetweenTraceRef.current);
      geoJSONBetweenTraceRef.current = null;
    }
  };

  const checkNetwork = (pointData) => {
    setLineColor("#000000");
    setTimeout(
      function () {
        setLineColor("#0080ff");
        alert("Network Connectivity OK!");
      }.bind(this),
      500
    );
  };

  const highlightelement = (latLag, isAdditional = 0) => {
    const newCircle = {
      latlng: latLag,
      radius: 10, // Set the desired radius here
      color: "red",
      fillColor: "#f03",
      fillOpacity: 0,
    };

    if (isAdditional > 0) {
      setCircles((prevCircles) => [...prevCircles, newCircle]);
    } else {
      setCircles([newCircle]);
    }
  };

  const handleTracing = (type) => {
    if (selectedFeature) {
      if (type === "down") handleFeatureClick(selectedFeature.id);

      if (type === "up") handleUpwardTrace(selectedFeature.id);

      if (type === "start") setTraceBetween([selectedFeature]);

      if (type === "end")
        setTraceBetween((prevArray) => [...prevArray, selectedFeature]);

      if (type === "length") {
        if (points.length > 1 && !isMeasuring) {
          clearLengthMapping();
        } else {
          setIsMeasuring((prevState) => !prevState);
          if (!isMeasuring)
            setPoints((prevPoints) => [
              ...prevPoints,
              L.latLng(
                selectedFeature.geometry.coordinates[1],
                selectedFeature.geometry.coordinates[0]
              ),
            ]);

          setMeasuringPoint(
            L.latLng(
              selectedFeature.geometry.coordinates[1],
              selectedFeature.geometry.coordinates[0]
            )
          );
        }
      }
    } else {
      alert("Please select any point element.");
    }
  };

  const onFeatureEachData = (feature, layer) => {
    layer
      .on({
        click: () => handleFeatureClick(feature.properties.id), //change
      })
      .setStyle({
        color: lineColor, // Use the lineColor state for the color
      });
  };

  const formatPopupContent = (data) => {
    return Object.entries(data)
      .map(([key, value]) => {
        if (value === null) value = "N/A"; // Handling null values
        return `<p class=""><span class="text-secondary">${key.replace(
          /_/g,
          " "
        )}: </span><span class="word-break-all">${value}</span></p>`;
      })
      .join("");
  };

  const toggleLeftSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

  const handleLayerChange = (layerName) => {
    setActiveLayer(layerName);
  };

  function onEachGeomDataFeature(feature, layer) {
    // if (feature.properties && feature.properties.label) {
    //     layer.bindTooltip(feature.properties.label, {
    //         className: "custom-tooltip",
    //         permanent: true,  // Make the tooltip always visible
    //         direction: 'auto'  // Automatically positions the tooltip in a sensible way
    //     });
    // }
  }

  const toggleObjectElement = (elementName) => {
    setVisibleElements((prevState) => ({
      ...prevState,
      [elementName]: !prevState[elementName],
    }));
  };

  const customIcon2 = new L.DivIcon({
    className: 'custom-div-icon',
    html: ` <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="lightblue" stroke="blue" stroke-width="2">
    <polygon points="12,2 22,22 2,22" />
  </svg>



`,
    iconSize: [20, 20],
    iconAnchor: [12, 24],
  });

  const pointToLayer = (feature, latlng) => {
    return L.marker(latlng, { icon: customIcon2 });
  };
  // const mapRef = useRef(null);
  // const captureMapImage = async () => {
  //   if (mapRef.current) {
  //     console.log(mapRef,"mapComponentRef")
  //     // Example using html2canvas library (install it via npm if not already)
  //     const canvas = await html2canvas(mapRef.current);
  //     console.log(canvas,"canvas")
  //     return canvas.toDataURL('image/jpeg');
  //   }
  //   throw new Error('Map component reference not available');
  // };
  // const generateMapPDF = async () => {
  //   console.log("first generateMapPDF")
  //   try {
  //     // Example: Capture map image as base64 or blob
  //     const mapImage = await captureMapImage(); // Implement captureMapImage according to your map component
  //     console.log(mapImage,"first mapImage")

  //     // Example: Generate PDF using a library like jsPDF or similar
  //     const pdf = new jsPDF();
  //     pdf.addImage(mapImage, 'JPEG', 10, 10, 180, 150); // Adjust dimensions and position as needed
  //     pdf.save('map.pdf');

  //     // Return the generated PDF blob if needed
  //     return pdf.output('blob');
  //   } catch (error) {
  //     console.error('Error generating PDF:', error);
  //     throw error;
  //   }
  // };

  // Example function to capture map image (replace with actual implementation)

  //zoom={zoom} maxZoom={30} zoomControl={false} ref={mapRef} center={[data.features[0].geometry.coordinates[1],data.features[0].geometry.coordinates[0]]}


  useEffect(() => {
    // console.log("It is working");
    if (geojsonData && mapRef.current) {
      // Create a GeoJSON layer
      const geoJsonLayer = L.geoJSON(geojsonData);
      
      // Get the bounds of the GeoJSON layer
      const bounds = geoJsonLayer.getBounds();

      // Fit map bounds to the new GeoJSON layer
      if (bounds.isValid()) {
        mapRef.current.fitBounds(bounds);
      }
    }
  }, [geojsonData]);


  
  useEffect(() => {
    // console.log("It is working");
    if (geojsonTalukaData && mapRef.current) {
      // Create a GeoJSON layer
      const geoJsonLayer = L.geoJSON(geojsonTalukaData);
      
      // Get the bounds of the GeoJSON layer
      const bounds = geoJsonLayer.getBounds();

      // Fit map bounds to the new GeoJSON layer
      if (bounds.isValid()) {
        mapRef.current.fitBounds(bounds);
      }
    }
  }, [geojsonTalukaData]);


  // console.log("THe data",setPointGeometry, )
  // console.log("THe data 1", measureMode)
  // console.log("THe data 2", setMeasureResult)
  // console.log("THe data 3", setDrawnLayers)



const[selectedDraw, setSelectedDraw] = useState();
  const handleSelectedData = (name,geometry) => {
    setSelectedDraw({
      layerName:name,
      geometry:geometry
    });

    console.log(name,geometry);
  }


  const[layerVisibility,setLayerVisibility] = useState(false);
const[circleData,setCircleData] = useState();
const[companyData,setCompanyData] = useState();
const[companyGeojson,setCompanyGeojson] = useState();
const [subDivisiongeojson, setSubDivisiongeojson]= useState();
const[filterEHVJson,setFilterEHVJson] = useState();
const[eHVLinegeojson,setEHVLinegeojson] = useState();
const[circleGeojson,setCircleGeojson] = useState();
const[divisionGeojson,setDivisionGeojson] = useState();

const[infraStructure,setInfraStructure] = useState([]);

const [organizations, setOrganizations] = useState([]);
const [divisions, setDivisions] = useState([]);


const [subDivisions, setSubDivisions] = useState([]);

const [organization, setOrganization] = useState([]);

const[searchId,setSearchId] = useState();

const[substationIds,setSubstationIds] = useState([]);
const[divisionData,setDivisionData] = useState([]);
const[subDivisionData,setSubDivisionData] = useState([]);
const [lineData, setLineData] = useState(null);
const[lineDataGeoJSON,setLineDataGeoJSON] = useState();

  useEffect(() => {

    console.error("circleData",circleData);
    if (!circleData || !Array.isArray(circleData) || circleData.length === 0) {
      setCircleGeojson([]);
      console.warn("Invalid circleData:", circleData);
      return;
    }

    const geojsonFeatures1 = circleData
      .filter(data => data.geometry && data.geometry.type === 'MultiPolygon')
      .map(data => ({
        type: 'Feature',
        properties: {
          id: data.id,
          name: data.properties?.organization_name || 'Unknown',
          type: 'Circle'
        },
        geometry: data.geometry
      }));

    if (geojsonFeatures1.length === 0) {
      console.warn("No valid features in circleData.");
      return;
    }

    const geojson = {
      type: 'FeatureCollection',
      features: geojsonFeatures1
    };

    setCircleGeojson(geojson);

    // Fit bounds
    // if (geojson.features.length > 0) {
    //   const bounds = geojson.features.flatMap(feature =>
    //     feature.geometry.coordinates.flatMap(polygon =>
    //       polygon.flatMap(ring =>
    //         ring.map(coord => [coord[1], coord[0]]) // Swap lat/lng to [lat, lng]
    //       )
    //     )
    //   );
    //   map.fitBounds(bounds);
    // }
    // console.log("The Circle Data is",circleData)
  }, [circleData]);

    // console.log("The cOMKPANY Data is",organization);


  useEffect(() => {
    if (!companyData || !Array.isArray(companyData) || companyData.length === 0) {
      setCompanyGeojson([]);
      console.warn("Invalid CompanyData:", companyData);
      return;
    }

    const geojsonFeatures2 = companyData
      .filter(data => data.geometry && data.geometry.type === 'MultiPolygon')
      .map(data => ({
        type: 'Feature',
        properties: {
          id: data.id,
          name: data.properties?.organization_name || 'Unknown',
          type: 'Company'
        },
        geometry: data.geometry
      }));

    if (geojsonFeatures2.length === 0) {
      console.warn("No valid features in circleData.");
      return;
    }

    const geojson = {
      type: 'FeatureCollection',
      features: geojsonFeatures2
    };

    setCompanyGeojson(geojson);

  }, [companyData]);


  useEffect(() => {
    console.log("Invalid divisionData:", divisionData);

    if (!divisionData || !Array.isArray(divisionData) || divisionData.length === 0) {
      setDivisionGeojson([]);
      console.warn("Invalid divisionData:", divisionData);
      return;
    }

    const geojsonFeatures2 = divisionData
      .filter(data => data.geometry && data.geometry.type === 'MultiPolygon')
      .map(data => ({
        type: 'Feature',
        properties: {
          id: data.id,
          name: data.properties?.organization_name || 'Unknown',
          type: 'SubDivision'
        },
        geometry: data.geometry
      }));

    if (geojsonFeatures2.length === 0) {
      console.warn("No valid features in circleData.");
      return;
    }

    const geojson = {
      type: 'FeatureCollection',
      features: geojsonFeatures2
    };

    // console.log("geojson",geojson);
    setDivisionGeojson(geojson);

  }, [divisionData]);



  useEffect(() => {
    console.error("subDivisiongeojson",subDivisionData);
    if (!subDivisionData || !Array.isArray(subDivisionData) || subDivisionData.length === 0) {
      console.warn("Invalid subDivisiongeojson:", subDivisionData);
      return;
    }

    const geojsonFeatures2 = subDivisionData
      .filter(data => data.geometry && data.geometry.type === 'MultiPolygon')
      .map(data => ({
        type: 'Feature',
        properties: {
          id: data.id,
          name: data.properties?.organization_name || 'Unknown',
          type: 'SubDivision'
        },
        geometry: data.geometry
      }));

    if (geojsonFeatures2.length === 0) {
      console.warn("No valid features in circleData.");
      return;
    }

    const geojson = {
      type: 'FeatureCollection',
      features: geojsonFeatures2
    };

    console.error(geojson);
    setSubDivisiongeojson(geojson);

  }, [subDivisionData]);




 // Simplified handleDistrictStyle function
//  const handleDistrictStyle = ({ color, opacity }) => {
//   setDistrictStyle({ color, opacity });
// };

// console.log("eHVLinegeojson",eHVLinegeojson);
// console.log("subDivisiongeojson:",subDivisiongeojson)



// useEffect(() =>{

// console.log("eHVLinegeojson",companyStyle);


useEffect(() => {
  const fetchGeoJSONData = async () => {
    try {
      const response = await axios.get(
        'https://geoserver.fornaxenergytech.com/geoserver/wfs', {
          params: {
            service: 'WFS',
            version: '1.0.0',
            request: 'GetFeature',
            typeName: 'discom:tmp_ht_lines',
            outputFormat: 'application/json',
          }
        }
      );
      const data = response.data;
      // console.log("Bayad Line data",data);
      // Filter the data based on substation_id
      const filteredData = {
        ...data,
        features: data.features.filter(feature => substationIds.includes(feature.properties.substation_id))
      };
      setLineData(filteredData);
    } catch (error) {
      console.error('Error fetching GeoJSON data:', error);
    }
  };

  fetchGeoJSONData();
}, [substationIds]);  
// },[searchId])

const[lineprojectids, setLineprojectids] = useState([]);
useEffect(() => {
  if (!lineData || !Array.isArray(lineData.features) || lineData.features.length === 0) {
    console.warn("Invalid LineData:", lineData);
    return;
  }

  const project6IdsArray = lineData.features.map(item => item.properties.project_id);
  // console.log("Line data Feature project_ids ",project6IdsArray);

  setLineprojectids(project6IdsArray);

  // Filter and map the features to the desired GeoJSON format
  const geojsonFeatures2 = lineData.features
    .filter(data => data.geometry && data.geometry.type === 'LineString')
    .map(data => {
      const { id, properties: { name }, geometry } = data;
      return {
        type: 'Feature',
        properties: {
          id,
          name,
          type: 'SubDivision'
        },
        geometry
      };
    });

  // Log filtered features for debugging
  // console.log("Line data Feature ",lineData);

  if (geojsonFeatures2.length === 0) {
    console.warn("No valid features in LineData.");
    return;
  }

  // Create GeoJSON object
  const geojson = {
    type: 'FeatureCollection',
    features: geojsonFeatures2
  };

  // Update state with the GeoJSON data
  setLineDataGeoJSON(geojson);


}, [lineData]);  


const [filteredObject, setFilteredObject] = useState([]);
const[selectedPointType, setSelectedPointType] = useState([]);

const [subMenuSection, setSubMenuSection] = useState(null);




  useEffect(() => {
    // Define an async function inside useEffect
    const fetchData = async () => {
      try {
        // Fetch data from the WFS service
        const response = await axios.get('https://geoserver.fornaxenergytech.com/geoserver/wfs', {
          params: {
            service: 'WFS',
            version: '1.0.0',
            request: 'GetFeature',
            typeName: 'discom:discom_location',
            outputFormat: 'application/json',
          }
        });

        // Assuming the response.data is a FeatureCollection with a features array
        const featureCollection = response.data.features;
        console.log("discom_location", featureCollection);

        // Filter the data based on lineprojectids
        const filterData = featureCollection.filter(item => lineprojectids.includes(item.properties.project_id));

        // Update state with filtered data
        setFilteredObject(filterData);
        
        console.log("Filtered Data", filterData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the async function
    fetchData();
  }, [lineprojectids]);


  const[objectgeojson,setObjectgeojson] = useState();
useEffect(() => {

  console.log("selectedPointType",selectedPointType);
  if (selectedPointType.length > 0) {
    // Ensure filteredObject is defined and not empty
    if (filteredObject && filteredObject.length > 0) {
      const filtered = filteredObject.filter(item => 
        selectedPointType.includes(item.properties.point_type)
      );
      // setFiltered(filtered);
      console.log("Filtered selectedPointType:", filtered);


      const geojsonFeatures2 = filtered
      .filter(data => data.geometry && data.geometry.type === 'Point')
      .map(data => ({
        type: 'Feature',
        properties: {
          id: data.id,
          name: data.properties?.point_type || 'Unknown',
          type: 'SubDivision'
        },
        geometry: data.geometry
      }));

    if (geojsonFeatures2.length === 0) {
      console.warn("No valid features in circleData.");
      return;
    }

    const geojson = {
      type: 'FeatureCollection',
      features: geojsonFeatures2
    };

    console.log("setObjectgeojson", geojson);
    console.log("setObjectgeojson", selectedPointType);

    if(selectedPointType.length > 0){
      setObjectgeojson(geojson);

    }else{
      setObjectgeojson([]);

    }


    }
  } else {

  }
}, [selectedPointType, filteredObject]);



const [gujaratHighwayData, setGujaratHighwayData] = useState(null);




useEffect(() => {
  // console.log("It is working");
  if (lineDataGeoJSON && mapRef.current) {
    // Create a GeoJSON layer
    const geoJsonLayer = L.geoJSON(lineDataGeoJSON);
    
    // Get the bounds of the GeoJSON layer
    const bounds = geoJsonLayer.getBounds();

    // Fit map bounds to the new GeoJSON layer
    if (bounds.isValid()) {
      mapRef.current.fitBounds(bounds);
    }
  }
}, [lineDataGeoJSON])

  useEffect(() => {
    // console.log("It is working");
    if (companyGeojson && mapRef.current) {
      // Create a GeoJSON layer
      const geoJsonLayer = L.geoJSON(companyGeojson);
      
      // Get the bounds of the GeoJSON layer
      const bounds = geoJsonLayer.getBounds();

      // Fit map bounds to the new GeoJSON layer
      if (bounds.isValid()) {
        mapRef.current.fitBounds(bounds);
      }
    }
  }, [companyGeojson]);


  useEffect(() => {
    // console.log("It is working");
    if (circleGeojson && mapRef.current) {
      // Create a GeoJSON layer
      const geoJsonLayer = L.geoJSON(circleGeojson);
      
      // Get the bounds of the GeoJSON layer
      const bounds = geoJsonLayer.getBounds();

      // Fit map bounds to the new GeoJSON layer
      if (bounds.isValid()) {
        mapRef.current.fitBounds(bounds);
      }
    }
  }, [circleGeojson]);


  
  useEffect(() => {
    // console.log("It is working");
    if (divisionGeojson && mapRef.current) {
      // Create a GeoJSON layer
      const geoJsonLayer = L.geoJSON(divisionGeojson);
      
      // Get the bounds of the GeoJSON layer
      const bounds = geoJsonLayer.getBounds();

      // Fit map bounds to the new GeoJSON layer
      if (bounds.isValid()) {
        mapRef.current.fitBounds(bounds);
      }
    }
  }, [divisionGeojson]);
  
  useEffect(() => {
    // console.log("It is working");
    if (subDivisiongeojson && mapRef.current) {
      // Create a GeoJSON layer
      const geoJsonLayer = L.geoJSON(subDivisiongeojson);
      
      // Get the bounds of the GeoJSON layer
      const bounds = geoJsonLayer.getBounds();

      // Fit map bounds to the new GeoJSON layer
      if (bounds.isValid()) {
        mapRef.current.fitBounds(bounds);
      }
    }
  }, [subDivisiongeojson]);

  // const selectedPointTypes = ['Transformer', 'Fuse']; 

  useEffect(() => {
    console.error("zoom:", zoom);

    if (zoom > 12) {
              setShowGeoJson(true);  // Show GeoJSON if zoom is greater than 15
            } else {
              setShowGeoJson(false); // Hide GeoJSON if zoom is 15 or below
            }
  }, [zoom]);
  

  useEffect(() =>{ 

    if(subMenuSection == "base-layer"){
      console.error("Base-layer check");
      setBaseLayer(prevBaseLayer => prevBaseLayer.filter(layer => layer !== "Talukas"));
    }
  },[subMenuSection])

  // const [zoomLevel, setZoomLevel] = useState(); // Initial zoom level

  const handleZoomChange = (map) => {
    setZoom(map.getZoom());
  console.error(map.getZoom());
  };

  // useEffect(()=>{

  // // console.error("FormData from Search ",zoom );
    
  // },[zoom])

  return (
    <>
      <TopNavbar sendDataToParent={handleDataFromChild}
      
      organizations={organizations}
       setOrganizations={setOrganizations}
       divisions={divisions} 
       setDivisions={setDivisions}
       subDivisions={subDivisions} 
       setSubDivisions={setSubDivisions}

       setSearchId={setSearchId}
       searchData={searchData}
        setSearchData={setSearchData}

      />
      <div className="app" style={{ position: "relative" }}>
        <div
          className={`sidebar leftsidebar-container ${
            sidebarVisible ? "visible" : "hidden"
          }`}
        >
          <LeftSidebar
            sendDataToParent={handleDataFromChild}
            setMapLayer={handleLayerChange}
            updateCenter={calculateCenter}
            addMerkers={setMarkers}
            objectElements={visibleElements}
            toggleObjectElements={toggleObjectElement}
            // activeBaseLayer={activeBaseLayer}
            handleBaseLayerChange={handleBaseLayerChange}
            baseLayer={baseLayer}
            selectedDistrictId={selectedDistrictId}
            // handleDistrictBaseLayerChange={handleDistrictBaseLayerChange}
          //  handleTalukaBaseLayerChange={handleTalukaBaseLayerChange}
            // selectedTaluka={selectedTaluka}
            errorMessage={errorMessage}
  isTalukaVisible={isTalukaVisible}
  // isDistrictVisble={isDistrictVisble}
  handleRefresh={handleRefresh}
  // setBaselayerVisble={setBaselayerVisble}
  searchData={searchData}
  setCircleData={setCircleData}
    // setLayerVisibility={setLayerVisibility}
    setFilterEHVJson={setFilterEHVJson}
    setPointDataElements={setPointDataElements}
    setInfraStructure={setInfraStructure}
    infraStructure={infraStructure}
    handleClearNetwork={handleClearNetwork}
    // handleDistrictStyle={handleDistrictStyle}
    setDistrictStyle={setDistrictStyle}
    setTalukaStyle={setTalukaStyle}
    setCompanyStyle={setCompanyStyle}
    companyStyle={companyStyle}
    setCompanyData={setCompanyData}
    setSubstationIds={setSubstationIds}
    setHighwayStyle={setHighwayStyle}
          organization={organization}
          setOrganization={setOrganization}
    organizations={organizations}
    setOrganizations={setOrganizations}
    divisions={divisions} 
    setDivisions={setDivisions}
    subDivisions={subDivisions} 
    setSubDivisions={setSubDivisions}


    setDivisionData={setDivisionData}
    setSubDivisionData={setSubDivisionData}

    setCircleStyle={setCircleStyle}
    setDivisionStyle={setDivisionStyle}
    setSubDivisionStyle={setSubDivisionStyle}
    circleStyle={circleStyle}
    divisionStyle={divisionStyle}
    subDivisionStyle={subDivisionStyle}
    talukaStyle={talukaStyle}
    districtStyle={districtStyle}
    highwayStyle={highwayStyle}

    measureDetail={measureDetail}
    
  selectedPointType={selectedPointType} 
  setSelectedPointType={setSelectedPointType}

  subMenuSection={subMenuSection}
  setSubMenuSection={setSubMenuSection}

  handleClearLineNetwork={handleClearLineNetwork}
  // isValueExist={isValueExist}
  clearnetwork={clearnetwork}
    
    
  // setDiscomLocationData={ setDiscomLocationData}
  setGujaratHighwayData={setGujaratHighwayData}
    // circleData={circleData}
    setMeasureMode={setMeasureMode}
    measureMode={measureMode}
    handleMeasureChange={handleMeasureChange}

            //generateMapPDF={generateMapPDF}
          />
        </div>
        <div className="main-content">
          <button className="toggle-switch" onClick={toggleLeftSidebar}>
            <img className="bars-icon" src="menu.png" />
          </button>
          <MapContainer
            zoom={zoom}
            zoomControl={false}
            ref={mapRef}
            center={center}
            maxBounds={gujaratBounds}
            minZoom={8}
         
          >

<ZoomTracker setZoom={setZoom} />
{/* 
<WMSTileLayer
         url="https://geoserver.fornaxenergytech.com/geoserver/wfs"
         layers={`discom:tmp_ht_lines`}
         format="image/png"
         transparent={true}
         opacity={0.7}
         attribution="InfraStructure Layer"
         zIndex={10000}
       /> */}
  {/* {infraStructure && infraStructure.map((layer, index) => (
       <div key={index} style={{ filter: 'drop-shadow(0 0 5px blue)' }}>
       <WMSTileLayer
         url="https://geoserver.fornaxenergytech.com/geoserver/wfs"
         layers={`discom:${layer}`}
         format="image/png"
         transparent={true}
         opacity={0.7}
         attribution="InfraStructure Layer"
         zIndex={index + 10}
       />
     </div>
      ))} */}
{/* 
{selectedPointType.length > 0 && lineprojectids.length > 0 && (
  <WMSTileLayer
    format="image/png"
    transparent={true}
    opacity={0.7}
    attribution="InfraStructure Layer"
    zIndex={1000 + 10}
    params={{
      CQL_FILTER: `point_type IN (${selectedPointType.map(type => `'${type}'`).join(', ')}) AND project_id IN (${lineprojectids.map(id => `'${id}'`).join(', ')})`
    }}
  />
)}
 */}





{showGeoJson && objectgeojson && (
        <GeoJSON
          data={objectgeojson}
          pointToLayer={pointToLayer}
          style={{
            color: 'blue',
            weight: 1,
            opacity: 0.5,
            fillColor: 'blue',
            fillOpacity: 0.1,
          }}
          onEachFeature={(feature, layer) => {
            if (feature.properties && feature.properties.name) {
              layer.bindPopup(
                `
                <div 
                  class="d-flex flex-column justify-content-center align-items-center" 
                  style="width: 100%; height: 50px;"
                >
                  <div style="font-size: 14px; font-weight: bold;" class="shadow text-center rounded">
                    ${feature.properties.name}
                  </div>
                  <div style="font-size: 12px;">
                    Coordinates: ${layer.getLatLng().lat.toFixed(5)}, ${layer.getLatLng().lng.toFixed(5)}
                  </div>
                </div>
                `
              );
            }
          }}
          
        />
      )}

      {gujaratHighwayData && (
        <GeoJSON
          data={gujaratHighwayData}
          style={highwayStyle}
          onEachFeature={(feature, layer) => {
            if (feature.properties && feature.properties.name) {
              layer.bindPopup(feature.properties.name);
            }
          }}
        />
      )}




{lineDataGeoJSON && (
        <GeoJSON data={lineDataGeoJSON} style={{ color: '#0252A8', weight: 2, opacity: 0.9 }} />
      )}
  

{measurementVisible &&(
    <MapEvents
          setPointGeometry={setPointGeometry}
          measureMode={measureMode}
          setMeasureResult={setMeasureResult}
          setDrawnLayers={setDrawnLayers}
          setSelectedData={setSelectedData}
          setMeasureDetail={setMeasureDetail}
        />
       )} 

{eHVLinegeojson && (
          <GeoJSON
          key={JSON.stringify(eHVLinegeojson)}
          data={eHVLinegeojson}
            style={{
              color: 'black',
              weight: 1,
              opacity: 1,
              zIndex:100
            }}
            onEachFeature={(feature, layer) => {
              layer.bindTooltip(feature.properties.point_type || 'No Type', {
                permanent: false,
                direction: 'auto'
              });
              layer.on({
                click: () => console.log(`Feature clicked: ${feature.properties.id}`)
              });
            }}
          />
        )}

   {/* <MapWrapper /> */}

   {/* { eHVLinegeojson && (
       <GeoJSON
       key={JSON.stringify(eHVLinegeojson)}
       data={ eHVLinegeojson}
         style={{
           color: 'black',
           weight: 2,
           opacity: 1,
           zIndex:100
         }}
      
       />


   )

  } */}

  
{ circleGeojson && (
  <GeoJSON
    key={JSON.stringify(circleGeojson)}
    data={circleGeojson}
    style={circleStyle}
    onEachFeature={(feature, layer) => {
      layer.bindTooltip(feature.properties.name || 'No Type', {
        permanent: false,
        direction: 'auto'
      });

      layer.on({
        click: () => {
          layer.setStyle({ zIndex: 2000 , fillColor: 'yellow' , color:'black'});
          // Custom CSS class can be added for boxShadow effect
          layer.getElement().classList.add('custom-highlight');
        },
       
      });
    }}
  />
)}


{ companyGeojson && (

<GeoJSON
key={JSON.stringify(companyGeojson)}
data={companyGeojson}
style={companyStyle}
  onEachFeature={(feature, layer) => {
    layer.bindTooltip(feature.properties.name || 'No Type', {
      permanent: false,
      direction: 'auto'
    });
    layer.on({
      click: () => console.log(`Feature clicked: ${feature.properties.id}`)
    });
  }}
/>
)}

{divisionGeojson && (

<GeoJSON
key={JSON.stringify(divisionGeojson)}
data={divisionGeojson}
style={divisionStyle}
  onEachFeature={(feature, layer) => {
    layer.bindTooltip(feature.properties.name || 'No Type', {
      permanent: false,
      direction: 'auto'
    });
    layer.on({
      click: () => console.log(`Feature clicked: ${feature.properties.id}`)
    });
  }}
/>
  )
}

{subDivisiongeojson && (

<GeoJSON
key={JSON.stringify(subDivisiongeojson)}
data={subDivisiongeojson}
style={subDivisionStyle}
  onEachFeature={(feature, layer) => {
    layer.bindTooltip(feature.properties.label || 'No Type', {
      permanent: false,
      direction: 'auto'
    });
    layer.on({
      click: () => console.log(`Feature clicked: ${feature.properties.id}`)
    });
  }}
/>
  )
}


                  {geojsonData && isMapVisble && (
          <GeoJSON
          key={geojsonData.id}
            data={geojsonData}
          style={districtStyle}
            onEachFeature={(feature, layer) => {
              layer.bindTooltip(feature.properties.name, { permanent: false, direction: "auto" });
              layer.on({
                click: () => handleFeatureClick(feature)
              });
            }}
          />
        )}

        {geojsonTalukaData && isMapVisble && (
           <GeoJSON
           key={JSON.stringify(geojsonTalukaData)}
           data={geojsonTalukaData}
           style={talukaStyle}
           onEachFeature={(feature, layer) => {
            
             layer.bindTooltip(feature.properties.name, { permanent: false, direction: "auto" });
             layer.on({
               click: () => handleFeatureClick(feature)
             });
           }}
         />

        )} 

        {/* {villages && (
          <GeoJSON
            key={JSON.stringify(villages)}
            data={{
              type: 'FeatureCollection',
              features: villages.map(village => ({
                type: 'Feature',
                properties: { id: village.id, name: village.name, type: 'village' },
                geometry: village.geometry
              }))
            }}
            style={{ color: 'black', weight: 1, fillOpacity: 0.5, zIndex: 1 }}
            onEachFeature={(feature, layer) => {
              layer.bindTooltip(feature.properties.name, { permanent: false, direction: "auto" });
              layer.on({
                click: () => handleFeatureClick(feature)
              });
            }}
          />
        )} */}
            <LayersControl>
              <ZoomControl position="bottomright" />
              <BaseLayer checked={activeLayer === "empty"} name="Empty">
                <TileLayer
                  url="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
                  maxZoom={30}
                  attribution=""
                />
              </BaseLayer>

              <BaseLayer
                checked={activeLayer === "OpenStreetMap"}
                name="OpenStreetMap"
              >
                <TileLayer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  maxZoom={30}
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />
                {/* {lineData && (
                 <TileLayer
                  url="http://3.109.36.188:8050/geoserver/discom/gwc/service/tms/1.0.0/tmp_ht_lines@EPSG%3A900913@png/{z}/{x}/{-y}.png"
                  maxZoom={30}
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                /> 
                 )} */}
                {/* <TileLayer
                  url="http://3.109.36.188:8050/geoserver/discom/gwc/service/tms/1.0.0/discom_location@EPSG%3A900913@png/{z}/{x}/{-y}.png"
                  maxZoom={30}
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                /> */}
                
              </BaseLayer>

              <BaseLayer checked={activeLayer === "Satellite"} name="Satellite">
                <TileLayer
                  url="https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
                  maxZoom={20}
                  subdomains={["mt1", "mt2", "mt3"]}
                />
              </BaseLayer>

              <BaseLayer checked={activeLayer === "BingMaps"} name="Bing Maps">
                <BingLayer
                  bingkey="AuhiCJHlGzhg93IqUH_oCpl_-ZUrIE6SPftlyGYUvr9Amx5nzA-WqGcPquyFZl4L"
                  type="AerialWithLabels"
                  maxNativeZoom={19}
                />
              </BaseLayer>
            </LayersControl>
            {markers && markers.map((marker) => (
              <div key={marker.value}>
                <Marker position={[marker.lng, marker.lat]} icon={customIcon}>
                  <Tooltip
                    className="custom-tooltip"
                    direction="bottom"
                    permanent
                  >
                    {marker.label}
                  </Tooltip>
                </Marker>
                <GeoJSON
                  data={JSON.parse(marker.feature)}
                  onEachFeature={onEachGeomDataFeature}
                />
              </div>
            ))}
            <SetViewToBounds markers={markers} />

            {circles && circles.map((circle, idx) => (
              <Circle
                key={idx}
                center={circle.latlng}
                radius={circle.radius}
                color={circle.color}
                fillColor={circle.fillColor}
                fillOpacity={circle.fillOpacity}
              />
            ))}
            {traceBetween && traceBetween.map((point, idx) => (
              <Circle
                key={idx}
                center={[
                  point.geometry.coordinates[1],
                  point.geometry.coordinates[0],
                ]}
                radius="10"
                color="red"
                fillOpacity="0"
              />
            ))}
            {/* {isMeasuring && <Marker position={measuringPoint}></Marker>} */}
            {isMeasuring && cursorPosition && (
              <>
                <Polyline
                  positions={[measuringPoint, cursorPosition]}
                  color="black"
                />
                {/* <Marker position={midpoint} icon={distanceText}></Marker> */}
              </>
            )}
            {points.length > 1 && (
              <>
                <Polyline positions={points} color="black">
                  {points.map((point, index) => {
                    if (index === 0) return null;
                    return (
                      <Tooltip
                        key={index}
                        direction="center"
                        offset={[0, 0]}
                        opacity={1}
                        permanent
                      >
                        <span>
                          Total Distance: {totalDistance.toFixed(2)} km
                        </span>
                        <br />
                      </Tooltip>
                    );
                  })}
                </Polyline>


              </>
            )}
            {isMeasuring && <MyComponent />}
          </MapContainer>
{/* 
          {measureResult && (
        <div className="measurement-result">
          <p>
            {`Measured ${measureMode === 'area' ? 'Area' : measureMode === 'length' ? 'Length' : 'Point'}: ${measureResult}`}
          </p>
        </div>
      )} */}
     
        </div>

        <div className="sidebar right-sidebar-container">
        { subMenuSection == "base-layer" || subMenuSection == "objects" ?  (
          <>
            {/* <RightSideBaseLayer
           activeBaseLayer={activeBaseLayer}
           selectedTaluka={selectedTaluka}
           isDistrictVisble={isDistrictVisble}
           isTalukaVisible={isTalukaVisible}
           setGeojsonData={setGeojsonData}
           setGeojsonTalukaData={setGeojsonTalukaData}
           setVillages={setVillages}
           /> */}

          <RightSideDrawBar
          setErrorMessage={setErrorMessage}
          baseLayer={baseLayer}
          // activeBaseLayer={activeBaseLayer}
          selectedTaluka={selectedTaluka}
          // isDistrictVisble={isDistrictVisble}
          isTalukaVisible={isTalukaVisible}
          setGeojsonData={setGeojsonData}
          setGeojsonTalukaData={setGeojsonTalukaData}
          // setVillages={setVillages}
          setMeasureMode={setMeasureMode}
          measureMode={measureMode}
          handleMeasureChange={handleMeasureChange}
          // setIsDistrictVisible={setIsDistrictVisible}
          selectedData={selectedData}
          measureResult={measureResult}
          setIsTalukaVisible={setIsTalukaVisible}

          setSelectedDistrictId={setSelectedDistrictId}
          selectedDistrictId={selectedDistrictId}
          subMenuSection={subMenuSection}
          measureDetail={measureDetail}
          // setIsTalukaVisible={setIsTalukaVisible}
          
          />
         </>
          ) : (
            ""
          )}
      
          {/* {
          subMenuSection == "organization"?  (
            <RightSidebar
              selectedFeature={featureProperties}
              setOpenedMenuOption={openedRightMenuOption}
              sendTracingData={handleTracing}
              networkDetails={networkDetails}
              getidInRighSidebar={getidInRighSidebar}
   
            />
          ) : (
            ""
          )} */}


{/*  */}
        </div>
       
      </div>
    </>
  );
};



const MapEvents = ({
  setPointGeometry,
  measureMode,
  setMeasureMode,
  setMeasureResult,
  setDrawnLayers,
  setSelectedData,
  setMeasureDetail
  // setSelectedLayer,
}) => {
  const map = useMap();

  const drawnItems = useRef(new L.FeatureGroup());
  const [drawing, setDrawing] = useState(false);
  const [currentLayer, setCurrentLayer] = useState(null);
  const[selectedLayer,setSelectedLayer] = useState();
  const [selectedLayerInfo, setSelectedLayerInfo] = useState(null);
  const [counter, setCounter] = useState({ point: 0, length: 0, area: 0, circle: 0 });
  const [layerNames, setLayerNames] = useState(new Map());
  const [cornerMarkers, setCornerMarkers] = useState(new Map());
  const [defaultStyles, setDefaultStyles] = useState(new Map()); // Store default styles


  useEffect(() => {
    map.addLayer(drawnItems.current);
    return () => {
      map.removeLayer(drawnItems.current);
    };
  }, [map]);

  const getLayerName = (type) => {
    let name = '';
    switch (type) {
      case 'point':
        name = `Point ${String.fromCharCode(65 + counter.point)}`;
        setCounter((prev) => ({ ...prev, point: prev.point + 1 }));
        break;
      case 'length':
        name = `Length ${String.fromCharCode(65 + counter.length)}`;
        setCounter((prev) => ({ ...prev, length: prev.length + 1 }));
        break;
      case 'area':
        name = `Area ${String.fromCharCode(65 + counter.area)}`;
        setCounter((prev) => ({ ...prev, area: prev.area + 1 }));
        break;
      case 'circle':
        name = `Circle ${String.fromCharCode(65 + counter.circle)}`;
        setCounter((prev) => ({ ...prev, circle: prev.circle + 1 }));
        break;
      default:
        break;
    }
    return name;
  };

  const finalizeCurrentLayer = () => {
    if (currentLayer) {
      setDrawing(false);
      setCurrentLayer(null);

      // Remove corner markers if they exist
      if (cornerMarkers.has(currentLayer._leaflet_id)) {
        cornerMarkers.get(currentLayer._leaflet_id).forEach((marker) => map.removeLayer(marker));
        setCornerMarkers((prev) => {
          const updatedMarkers = new Map(prev);
          updatedMarkers.delete(currentLayer._leaflet_id);
          return updatedMarkers;
        });
      }
    }
  };

  const handleDoubleClick = useCallback(() => {
    if (drawing) {
      finalizeCurrentLayer();
    }
  }, [drawing, finalizeCurrentLayer]);

  const handleLayerClick = ({layer,name}) => {
    // Check if the clicked layer is already selected
    if (selectedLayer && selectedLayer._leaflet_id === layer._leaflet_id) {
      // The layer is already selected, so we should remove it
      drawnItems.current.removeLayer(layer); // Remove the layer from the drawnItems feature group
  
      // Reset the selected layer state
      setSelectedLayer(null);
    } else {
      // Get the previous selected layer
      const prevSelectedLayer = selectedLayer;
  
      // Reset the style of the previously selected layer
      if (prevSelectedLayer) {
        const defaultStyle = defaultStyles.get(prevSelectedLayer._leaflet_id) || { color: '#3388ff' }; // Default color
        prevSelectedLayer.setStyle(defaultStyle);
      }
  
      // Set the style of the currently clicked layer
      const selectedLayerStyle = { color: 'red' };
      layer.bindTooltip("Selected",name);
  
      // Update the selected layer state
      setSelectedLayer(layer);
  
      // Get the name of the clicked layer
      // const name = layerNames.get(layer._leaflet_id);
  
      // Calculate measurement based on the layer type
      let measurement = null;
      let type = '';
      
      if (layer instanceof L.Polygon) {
        type = 'area';
        // Calculate area in square kilometers
        measurement = (L.GeometryUtil.geodesicArea(layer.getLatLngs()[0]) / 1e6).toFixed(3); // Convert to km² and round to 3 decimal places
      } else if (layer instanceof L.Polyline) {
        type = 'length';
        // Calculate length in kilometers
        measurement = (L.GeometryUtil.length(layer.getLatLngs()) / 1000).toFixed(3); // Convert to km and round to 3 decimal places
      } else if (layer instanceof L.Marker) {
        type = 'point';
        // Get latitude and longitude
        const latlng = layer.getLatLng();
        measurement = {
          latitude: latlng.lat.toFixed(3),
          longitude: latlng.lng.toFixed(3),
        };
      } else if (layer instanceof L.Circle) {
        type = 'circle';
        // Calculate area in square kilometers
        const radiusInKm = layer.getRadius() / 1000; // Convert to kilometers
        measurement = (Math.PI * radiusInKm * radiusInKm).toFixed(3); // Area in km² and round to 3 decimal places
      }
  
      // Store the detail
      const detail = { measurement, type, name };
  
      // Optionally update measure result or other state as needed
      setMeasureDetail(detail);
      console.log("Detail:", detail);
    }
  };
  
  // useEffect(() => {
  //   if (selectedLayer) {
  //     console.log("Selected Layer Updated:", selectedLayer);
  //     const prevSelectedLayer = selectedLayer;
  
  //     // Reset the style of the previously selected layer
  //     if (prevSelectedLayer) {
  //       const defaultStyle = defaultStyles.get(prevSelectedLayer._leaflet_id) || { color: 'red' }; // Default color
  //       prevSelectedLayer.setStyle(defaultStyle);
  //     }
  //   }
  // }, [selectedLayer]);
  
  
  useEffect(() => {
    if (measureMode) {
      // Finalize any ongoing drawing when measureMode changes
      if (drawing) {
        finalizeCurrentLayer();
      }

      const drawControl = new L.Control.Draw({
        position: 'topright',
        edit: {
          featureGroup: drawnItems.current,
          poly: { allowIntersection: false },
        },
        draw: {
          polyline: measureMode === 'length',
          polygon: measureMode === 'area',
          circle: measureMode === 'circle',
          marker: measureMode === 'point',
          rectangle: false,
          circlemarker: false,
        },
      });

      map.addControl(drawControl);

      map.on(L.Draw.Event.CREATED, (e) => {
        const layer = e.layer;
        const name = getLayerName(measureMode);

        layer.on('click', () => handleLayerClick({layer,name}));
        layer.bindTooltip(name, { permanent: true, direction: 'top' }).addTo(map);
        drawnItems.current.addLayer(layer);
        setLayerNames((prev) => new Map(prev).set(layer._leaflet_id, name));

        let latlngs = [];
        let type = '';

        if (layer instanceof L.Marker) {
          latlngs = [layer.getLatLng()];
          type = 'point';
        } else if (layer instanceof L.Polyline) {
          latlngs = layer.getLatLngs();
          type = 'length';
          if (layer instanceof L.Polygon) {
            type = 'area';
            latlngs = latlngs[0];
          }
        } else if (layer instanceof L.Circle) {
          latlngs = [layer.getLatLng()];
          type = 'circle';
        }

        const cornerNames = 'abcdefghijklmnopqrstuvwxyz'.split('');
        const geometry = latlngs.map((latlng, index) => ({
          name: cornerNames[index],
          lat: latlng.lat.toFixed(6),
          long: latlng.lng.toFixed(6),
        }));

        setSelectedLayerInfo({ layer, name, latlngs, type });
        setSelectedData({ layername: name, geometry });

        setCurrentLayer(layer);
      });

      return () => {
        map.removeControl(drawControl);
        map.off(L.Draw.Event.CREATED);
      };
    }
  }, [map, measureMode, drawnItems, getLayerName, setLayerNames, setSelectedData, setSelectedLayerInfo, finalizeCurrentLayer, drawing, layerNames, selectedLayer, setMeasureResult]);

  return null;
};



const SetViewToBounds = ({ markers }) => {
  const map = useMap(); // This hook provides the map instance

  useEffect(() => {
    if (markers.length > 0) {
      const bounds = L.latLngBounds(
        markers.map((marker) => L.latLng(marker.lng, marker.lat))
      );
      map.fitBounds(bounds);
    }
  }, [markers, map]); // map is a stable object and does not cause re-renders

  return null; // This component does not render anything
};





export default MapComponent;
